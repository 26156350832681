import React from 'react';

import { DisclaimerText } from 'reaxl';

export const renderSummaryContent = (summaryContentData) => {
    const { summaryContent } = summaryContentData || {};

    return summaryContent && (
        <>
            <div
                id="summaryContent"
                className="margin-vertical-3 row"
            >
                <p
                    className="text-size-200 col-xs-12 col-sm-12"
                    data-qaid="cntnr-summaryContent"
                >
                    {summaryContent}
                </p>
            </div>
            <hr />
        </>
    );
};

export const renderCustomLongFormContent = (summaryContent) => {
    if (!summaryContent) {
        return;
    }
    const { customAddOnContent = {} } = summaryContent;
    const {
        title: customTitle,
        body: customBody,
        disclaimer: customDisclaimer,
    } = customAddOnContent;

    return customAddOnContent && (
        <div
            id="customLongFormContent"
            className="long-form-content margin-vertical-3 margin-horizontal-2"
        >

            {customTitle && (
                <div className="row">
                    <h2
                        className="display-inline-block text-size-500 text-bold margin-bottom-1"
                        data-qaid="lbl-SRPCrawlPathCustomLongFormTitle"
                    >
                        {customTitle}
                    </h2>
                </div>
            )}

            {customBody && (
                <div
                    className="row"
                    data-qaid="lbl-SRPCrawlPathCustomLongFormBody"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: customBody }}
                />
            )}

            {customDisclaimer && (
                <div
                    className="row"
                    data-qaid="lbl-SRPCrawlPathCustomLongFormDisclaimer"
                >
                    <DisclaimerText dangerouslySetInnerHTML={{ __html: customDisclaimer }} />
                </div>
            )}
        </div>
    );
};
