import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

// Bonnet Utils
import { useDevice } from '@bonnet/core/device';

// ATC-UI Utils & hooks
import { formattedNumber } from 'atc-js';

import { ListColumns, SeeMore } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { renderCustomLongFormContent, renderSummaryContent } from '@/utilities/CrawlpathUtils';

import {
    queryDuck,
    userDuck,
} from '@/ducks';

import flpCitiesCrawlPathsDuck from '@/ducks/flp/flpCitiesCrawlPathsDuck';
import {
    srpAdditionalCrawlPathsDuck,
    srpCitiesCrawlPathsDuck,
    srpContentDuck,
    srpCrawlPathsDataMapsDuck,
    srpFiltersDuck,
    srpListingTypesCrawlPathsDuck,
    srpLocationTypeDuck,
    srpMakeCrawlPathsDuck,
    srpModelCrawlPathsDuck,
    srpTrimCrawlPathsDuck,
    srpYearCrawlPathsDuck } from '@/ducks/srp';

import CrawlPathNationalLinks from './CrawlPathNationalLinks';

const formatString = (string) => string.split(' ').join('-').toLowerCase();

const listColumns = (items) => (
    <ListColumns
        className="list-unstyled"
        xs={1}
        sm={3}
        md={4}
        lg={4}
        items={items}
    />
);

const renderSeeMoreLinks = (label, items, device) => {
    const expanded = {
        label: 'View Fewer ' + label,
        glyph: 'menu-up',
    };
    const collapsed = {
        label: 'View All ' + label,
        glyph: 'menu-down',
    };
    const greaterThanSmall = _get(device, 'greaterThan.sm', false);
    const isSm = _get(device, 'is.sm', false);
    const isXs = _get(device, 'is.xs', false);

    if (greaterThanSmall && items.length < 41 || isSm && items.length < 19 || isXs && items.length < 4) {
        return listColumns(items);
    }
    return (
        <SeeMore
            collapsed={collapsed}
            expanded={expanded}
        >
            {listColumns(items)}
        </SeeMore>
    );
};

const priceFormat = (startingPrice) => {
    const priceValue = startingPrice && formattedNumber({
        value: startingPrice,
        style: 'currency',
        currency: 'USD',
        formatPretty: true,
    });
    return ' ' + priceValue;
};

function VehicleCount({ count, startingPrice }) {
    return (
        <div className="text-size-200 margin-bottom-3">
            {`${count} for sale${(startingPrice && startingPrice !== 0 ? ' starting at ' + priceFormat(startingPrice) : '')}`}
        </div>
    );
}

function CrawlPathList({ id, qaId, heading, headingClasses, className = 'makes-listing-results', children }) {
    return (
        <div
            id={id}
            className={className}
        >
            {heading && (
                <h2
                    className={clsx(headingClasses, 'display-inline-block text-size-500 text-bold')}
                    data-qaid={qaId}
                >
                    {heading}
                </h2>
            )}
            {children}
        </div>
    );
}

function CrawlPathListItem({ link, title, qaId, linkText, count, startingPrice }) {
    return (
        <Fragment key={`crawlpath-${qaId}`}>
            <a
                href={link}
                title={title}
                data-qaid={formatString(qaId)}
            >
                {linkText}
            </a>
            {!!count && (
                <VehicleCount
                    count={count}
                    startingPrice={startingPrice}
                />
            )}
        </Fragment>
    );
}

function CrawlPathSectionContainer({ summaryContent, summaryContentData, children }) {
    return (
        <div
            data-qaid="cntr-crawl-path-footer"
            className="margin-bottom-5"
        >
            {renderCustomLongFormContent(summaryContentData)}

            {summaryContent && renderSummaryContent(summaryContentData)}

            {children}
        </div>
    );
}

function CrawlPathLinks() {
    const device = useDevice();

    const hasZip = useSelector((state) => _has(srpFiltersDuck.selectors.getFiltersValues(state), 'zip', false));

    const { conditionData, pricingData, stylesData } = useSelector(srpAdditionalCrawlPathsDuck.selectors.getDuckState);
    const { yearsData } = useSelector(srpYearCrawlPathsDuck.selectors.getDuckState);
    const { makesData } = useSelector(srpMakeCrawlPathsDuck.selectors.getDuckState);
    const { modelData } = useSelector(srpModelCrawlPathsDuck.selectors.getDuckState);
    const { trimData } = useSelector(srpTrimCrawlPathsDuck.selectors.getDuckState);
    const { listingTypeData } = useSelector(srpListingTypesCrawlPathsDuck.selectors.getDuckState);
    const { summaryContentData } = useSelector(srpContentDuck.selectors.getDuckState);
    const { makesMap, vehicleStylesMap, modelsMap } = useSelector(srpCrawlPathsDataMapsDuck.selectors.getDuckState);
    const isNational = useSelector(srpLocationTypeDuck.selectors.getDuckState);
    const queryList = useSelector(queryDuck.selectors.getDuckState);
    const userLocation = useSelector(userDuck.selectors.getLocation);

    // cities data may come from flp cities duck
    const { citiesData: srpCitiesData } = useSelector(srpCitiesCrawlPathsDuck.selectors.getDuckState);
    const { citiesData: flpCitiesData } = useSelector(flpCitiesCrawlPathsDuck.selectors.getDuckState);
    const citiesData = !_isEmpty(srpCitiesData) ? srpCitiesData : flpCitiesData;

    const hasCrawlPathData = !_isEmpty(makesData) || !_isEmpty(modelData) || !_isEmpty(yearsData) || !_isEmpty(trimData) || !_isEmpty(citiesData);

    const {
        crawlPath: [enableCrawlPath, {
            showYearForTrimList,
            summaryContent,
        }],
    } = useFeatures(['crawlPath']);

    const listingTypeTitle = queryList.listingType && startCase(camelCase(queryList.listingType)) || '';

    const makeKnown = queryList.makeCode && !Array.isArray(queryList.makeCode);
    const modelKnown = queryList.modelCode && !Array.isArray(queryList.modelCode);
    const trimKnown = queryList.trimCode && !Array.isArray(queryList.trimCode);

    const makeName = makeKnown && makesMap ? makesMap[queryList.makeCode.toUpperCase()] : '';
    const vehicleStyleName = queryList.vehicleStyleCode && !Array.isArray(queryList.vehicleStyleCode) && vehicleStylesMap ? vehicleStylesMap[queryList.vehicleStyleCode.toUpperCase()] : '';
    const modelName = modelKnown && modelsMap ? modelsMap[queryList.modelCode] : '';
    const trimName = trimKnown ? queryList.trimCode?.split('|')?.[1] : '';

    const searchTitle = vehicleStyleName ? `${makeName} ${vehicleStyleName}` : `${makeName} ${modelName} Cars`;
    const sectionTitle = `Find ${makeName} ${modelName} ${trimName} Cars for Sale by Year`.replace(/\s+/g, ' ').trim();
    const yearParam = queryList.startYear || '';

    const subText = (value) => `${startCase(camelCase(value))} ${yearParam} ${searchTitle}`.replace(/\s+/g, ' ').trim();

    const getCityState = () => {
        const { cityState } = citiesData;
        return cityState ? ` in ${cityState}` : ' For Sale';
    };

    const isValidCrawlData = (data) => !_isEmpty(data) && data?.links?.length > 0 && data?.links?.[0]?.link;

    // ListingTypes crawlpath for National/Local
    const renderListingTypes = () => {
        if (!_isEmpty(listingTypeData)) {
            const items = listingTypeData.links.map((item) => (
                <CrawlPathListItem
                    {...item}
                    key={item.link}
                    title={subText(item.value)}
                    qaId={`lnk-listingtype-${item.value}`}
                    linkText={subText(item.value).concat(getCityState())}
                />
            ));

            return (
                <CrawlPathList
                    id="typeListingResults"
                    className="type-listing-results"
                >
                    {/* TODO: this should not be creating an empty div for spacing */}
                    <div className="margin-vertical-4" />
                    <div className="listing-types">
                        {listColumns(items)}
                    </div>
                    {/* TODO: this should not be creating an empty div for spacing */}
                    <div className="margin-vertical-4" />
                </CrawlPathList>
            );
        }
    };

    // Years crawlpath for National/Local
    const renderModelYearsList = () => {
        const items = yearsData?.links?.map((item) => (
            <CrawlPathListItem
                {...item}
                key={item.link}
                title={item.value}
                qaId={`lnk-model-${item.value}`}
                linkText={`${item.value} ${makeName} ${modelName} ${trimName} ${getCityState()}`}
            />
        ));

        return (
            <CrawlPathList
                id="yearsListingResults"
                qaId="lbl-SRPCrawlPathYearsFooterTitle"
                className="years-listing-results"
                heading={sectionTitle}
            >
                <div className="model-years margin-bottom-3">
                    {listColumns(items)}
                </div>
            </CrawlPathList>
        );
    };

    // Makes Crawlpaths
    const renderMakesList = () => {
        const items = makesData?.links?.map((item) => (
            <CrawlPathListItem
                {...item}
                key={item.value}
                title={item.value}
                qaId={`lnk-make-${item.value}`}
                linkText={`${listingTypeTitle} ${yearParam} ${item.value} For Sale`.replace(/\s+/g, ' ').trim()}
            />
        ));

        return (
            <CrawlPathList
                id="makesListingResults"
                qaId="lbl-SRPCrawlPathFooterTitle"
                heading={`Find ${listingTypeTitle} ${yearParam} ${searchTitle} for Sale by Make`.replace(/\s+/g, ' ').trim()}
            >
                {makesData?.links?.length > 0 && renderSeeMoreLinks('Makes', items, device)}
            </CrawlPathList>
        );
    };

    // Model Crawlpaths
    const renderModelList = () => {
        const headingTitle = `${yearParam} ${searchTitle}`.replace(/\s+/g, ' ').trim();
        const items = modelData?.links?.map((item) => (
            <CrawlPathListItem
                {...item}
                key={item.value}
                title={item.value}
                qaId={`lnk-model-${item.value}`}
                linkText={`${listingTypeTitle} ${yearParam} ${makeName} ${item.value} For Sale`.replace(/\s+/g, ' ').trim()}
            />
        ));

        return (
            <CrawlPathList
                id="modelListingResults"
                qaId="lbl-SRPCrawlPathFooterTitle"
                heading={`Find ${listingTypeTitle} ${headingTitle} for Sale by Model`.replace(/\s+/g, ' ').trim()}
            >
                {modelData?.links?.length > 0 && renderSeeMoreLinks('Models', items, device)}
            </CrawlPathList>
        );
    };

    // Trims crawlpaths
    const renderTrimList = () => {
        const yearForTrim = (enableCrawlPath && showYearForTrimList && queryList?.startYear) ? yearParam : '';
        const headingTitle = `Find ${listingTypeTitle} ${yearForTrim} ${makeName} ${modelName} Cars for Sale by Trim`.replace(/\s+/g, ' ').trim();

        // For FBA, trim list is still rendered when user filters by a trim, but the trim they filtered will be excluded in the list
        const filteredTrimData = trimName ? trimData?.links?.filter((item) => item.value?.split('|')?.[1] !== trimName) : trimData?.links;
        const items = filteredTrimData?.map((item) => {
            // create full trim title with year make and model (E.g. 'CIVIC|Si' => '2020 Honda Civic Si')
            const trimTitle = `${listingTypeTitle} ${yearForTrim} ${makeName} ${modelName} ${item.value?.split('|')?.[1]} For Sale`;
            return (
                <CrawlPathListItem
                    {...item}
                    key={item.value}
                    title={item.value}
                    qaId={`lnk-trim-${item.value}`}
                    linkText={trimTitle.replace(/\s+/g, ' ').trim()}
                />
            );
        });

        return (
            <CrawlPathList
                id="modelListingResults"
                qaId="lbl-SRPCrawlPathFooterTitle"
                heading={headingTitle}
            >
                {trimData?.links?.length > 0 && renderSeeMoreLinks('Trims', items, device)}
            </CrawlPathList>
        );
    };

    /*  Render All Cities crawlpath for Local and Major Cities crawlpath for National  */
    const renderCitiesList = () => {
        const locationTitle = makeName ? `${makeName} ${modelName} ${vehicleStyleName} ${trimName}` : 'Cars';
        const items = citiesData?.links?.map((item) => (
            <CrawlPathListItem
                {...item}
                key={item.city}
                title={item.city}
                qaId={`lnk-city-${item.city}`}
                linkText={`${listingTypeTitle} ${yearParam} ${locationTitle} For Sale in ${item.city}`.replace(/\s+/g, ' ').trim()}
            />
        ));

        const cityNationalTitle = (!isNational && userLocation?.state) ? `in ${' '} ${userLocation.state}` : '';
        const headingTitle = `Find ${listingTypeTitle} ${yearParam} ${makeName} ${modelName} ${vehicleStyleName} ${trimName} Cars for Sale by City ${cityNationalTitle}`.replace(/\s+/g, ' ').trim();

        return (
            <CrawlPathList
                id="citiesListingResults"
                className="cities-listing-results"
                headingClasses="margin-top-4 margin-bottom-0"
                qaId="lbl-SRPCrawlPathCitiesFooterTitle"
                heading={headingTitle}
            >
                {citiesData?.links?.length > 0 && renderSeeMoreLinks('Cities', items, device)}
            </CrawlPathList>
        );
    };

    if (hasCrawlPathData) {
        return (
            <CrawlPathSectionContainer
                summaryContent={summaryContent}
                summaryContentData={summaryContentData}
            >
                {!!isValidCrawlData(yearsData) && renderModelYearsList()}
                {!!isValidCrawlData(trimData) && renderTrimList()}
                {!!isValidCrawlData(modelData) && renderModelList()}
                {!!isValidCrawlData(makesData) && renderMakesList()}
                {!!isValidCrawlData(citiesData) && renderCitiesList()}

                <CrawlPathNationalLinks
                    conditionData={conditionData}
                    pricingData={pricingData}
                    stylesData={stylesData}
                    hasZip={hasZip}
                />

                {hasZip && listingTypeData && renderListingTypes()}
            </CrawlPathSectionContainer>
        );
    }

    if (!_isEmpty(citiesData) || (summaryContentData && Object.keys(summaryContentData).length > 0)) {
        // Return city crawlpath only for non-canonical zip pages
        return (
            <CrawlPathSectionContainer
                summaryContent={summaryContent}
                summaryContentData={summaryContentData}
            >
                {isValidCrawlData(citiesData) && renderCitiesList()}
            </CrawlPathSectionContainer>
        );
    }

    return null;
}

export default CrawlPathLinks;
